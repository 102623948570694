import React from 'react'

const ContactForm = props => {
  const { title, model } = props
  return (
    <div className=''>
      <h2 className=' text-xl md:text-2xl 2xl:text-3xl text-primary md:pb-4 md:border-b border-secondary'>{title}</h2>
      <form 
        className="py-2 md:py-6 relative" 
        name="contact" 
        method="POST" 
        data-netlify="true"
        netlify-honeypot="bot-field"
        action="/contact-us"
      >
        <input type="hidden" name="bot-field" /> {/*For bots - filtered as spam*/}
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value={model ? ("Gazelle Site Inquiry for: " + model) : "Gazelle site contact form submission"}  /> {/*email subject for netlify*/}
        <input required="true" type="text" name="name" placeholder="Full Name*" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <input type="mobile" name="phone" placeholder="Phone Number" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <input required="true" type="email" name="email" placeholder="Email*" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <input type="text" name="job" placeholder="Job Role" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <input type="text" name="company" placeholder="Company Name" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <input type="text" name="country" placeholder="Country" className="my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none " />
        <textarea
          name="message"
          class="h-80 mb-4 my-3 w-full  py-2 pl-4 text-sm rounded-md border focus:outline-none resize-vertical"
          aria-label="Textarea"
          required="true"
          placeholder="Message*"
        />
        <div className='mt-6'>
          <button
            type="submit"
            className='hover:text-primary hover:bg-transparent border hover:border-primary w-60 text-lg absolute bottom-0 right-0 py-2 rounded-md bg-primary text-white transition-all duration-150'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
